<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑" : "新增" }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 6 }" :wrapper-col="{ span: 16 }">
          <a-form-model-item prop="product_id" label="商品">
            <a-select v-model="form.product_id" @change="changeGoods">
              <a-select-option v-for="val in productList" :key="val.id" :value="val.id">
                {{ val.product_name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="date" label="批次">
            <a-select v-model="form.date" style="width: 100%" @change="changeBatch">
              <a-select-option
                      v-for="(val, index) in BatchListInfo.pici"
                      :key="val.batch"
                      :value="val.batch"
              >
                {{ val.batch }}
              </a-select-option>
            </a-select>
          </a-form-model-item>

          <a-form-model-item prop="number" label="编号">
            <a-select v-model="form.number" style="width: 100%">
              <a-select-option
                      v-for="val in numberList"
                      :key="val"
                      :value="val"
              >
                {{ val }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="title" label="标题">
            <a-select v-model="form.title" disabled @change="onChange">
              <a-select-option v-for="(val, index) in titleList" :key="index" :value="val.title">
                {{ val.title }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="result" label="描述" v-if="form.title">
            <a-select mode="multiple" v-model="form.result">
              <a-select-option v-for="(val, index) in results" :key="index" :value="val.value">
                {{ val.label }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="image" label="上传图片">
            <a-upload
              accept=".jpeg,.jpg,.png,.gif,.bmp,.tiff,.tif,.webp,.svg,.ico,.raw"
              :action="uploadUrl"
              list-type="picture-card"
              :file-list="form.image"
              @preview="handlePreview"
              @change="handleChange"
              :maxCount="3"
              name="file">
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
    <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
    >
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>

<script>
import { traceabilityAdd, traceabilityEdit, fromProductList, tracePici } from "@/api/from";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}
export default {
  name: "FormModal",
  props: ["visible", "form", "reNumber", "BatchList", "clientsClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      previewImage: "",
      previewVisible: false,
      numberList: [],
      BatchListInfo: [],
      titleList: [
        {
          title: '原料验收',
          describe: ['原料索证齐全', '原料验收合格'],
        },
        {
          title: '生产投料',
          describe: ['根据订单生产'],
        },
        {
          title: '半成品检测',
          describe: ['半成品检测合格'],
        },
        {
          title: '成品检测',
          describe: ['成品检测合格'],
        },
        {
          title: '成品出库',
          describe: ['成品出库检测合格'],
        }
      ],
      data: [
        {
          title: '原料验收',
          result: [],
          image: []
        },
        {
          title: '生产投料',
          result: [],
          image: []
        },
        {
          title: '半成品检测',
          result: [],
          image: []
        },
        {
          title: '成品检测',
          result: [],
          image: []
        },
        {
          title: '成品出库',
          result: [],
          image: []
        }
      ],
      productList: [],
      uploadUrl: process.env.VUE_APP_NEW_URL + '/comapi/UploadApi',
      fileList1: [],
      levelOptions: [
        { id: "0", name: "0" },
        { id: "1", name: "1" },
        { id: "2", name: "2" },
        { id: "3", name: "3" },
      ],
      rules: {
        product_id: [
          { required: true, message: "请选择商品", trigger: "change" }
        ],
        date: [
          { required: true, message: "请选择批次", trigger: "change" }
        ],
        number: [{ required: true, message: "请输入编号", trigger: "blur" }],
        title: [
          { required: true, message: "请选择标题", trigger: "change" }
        ],
        result: [
          { required: true, message: "请选择描述", trigger: "change" }
        ]
      },
      loading: false,
    };
  },
  watch: {
    'BatchList': {
      handler() {
        this.BatchListInfo = this.BatchList
      },
      deep: true
    },
    'reNumber': {
      handler() {
        this.numberList = this.reNumber
      },
      deep: true
    }
  },
  computed: {
    results() {
      if (this.form.title) {
        const data = this.titleList.filter((item) => item.title === this.form.title);
        if (data.length > 0) {
          return data[0].describe.map((item) => ({ label: item, value: item }));
        }
        return [];
      }
      return [];
    }
  },
  created() {
    fromProductList({ page: 1, page_size: 9999 })
    .then((data) => {
      this.productList = data.data;
    })
  },
  methods: {
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancel() {
      this.previewVisible = false;
    },
    async changeBatch(val) {
      delete this.form.number
      this.numberList = this.BatchList.pici.filter(obj => obj.batch == val).map(obj => obj.number).flat();
    },
    async changeGoods(val) {
      let res1 = await tracePici({ product_id: val });
      delete this.form.date
      delete this.form.number
      this.BatchListInfo = res1.data;
      this.BatchList.pici = res1.data.pici
    },
    onChange() {
      this.form.result = []
    },
    handleChange({ fileList }) {
      this.$set(this.form,'image',fileList);
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let form = this.form
          this.loading = true;
          let func = form.id ? traceabilityEdit : traceabilityAdd;
          form.image = form.image?.filter((item) => item.status && item.status === 'done')
                  .map((item) => {
                    if (item.url) return item.url;
                    return item.response.data.file_url;
                  });
          func(form)
            .then(() => {
              this.$message.success(form.id ? "修改成功" : "新增成功");
              this.$emit(form.id ? "update" : "create");
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      // if(!this.form.id) {
      //   this.$refs.form.resetFields();
      // }
    },
  },
};
</script>

<style scoped></style>
